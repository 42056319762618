import { create } from 'zustand';
import {produce} from 'immer';
import {AuthUser} from "aws-amplify/auth";
import {SelectOption} from "../components/form/formTypes.ts";
import {useContext} from "./useContext.ts";

export const isSmallScreenDevice = window.innerWidth < 900;

export const UI_COMPONENTS = [
  'MODAL',
  'BUILDING_INFORMATION',
  'PERSON_INFORMATION',
  'TIME_CONTROLS',
  'EDIT_TOWN_LAYOUT',
  'SETTLEMENT_ACTIONS',
  'SETTLEMENT_FILTERS',
  'MAP_LEGEND',
  'SETTLEMENT_NAME',
  'INITIATE_EDIT_BUTTONS',
  'MAP_SCALE',
  'RENDER_SETTINGS',
  'ZOOM_CONTROLS',
] as const;

export type UiComponent = typeof UI_COMPONENTS[number]

export const userComponentOptions: SelectOption<UiComponent>[] = [
  {
    value: 'TIME_CONTROLS',
    label: 'Time controls'
  },
  {
    value: 'SETTLEMENT_FILTERS',
    label: 'Map layers & filters'
  },
  {
    value: 'MAP_LEGEND',
    label: 'Map legend'
  },
  {
    value: 'SETTLEMENT_NAME',
    label: 'Settlement name'
  },
  {
    value: 'MAP_SCALE',
    label: 'Map scale'
  },
  {
    value: 'ZOOM_CONTROLS',
    label: 'Zoom controls'
  }
];

const renderSettings: SelectOption<UiComponent> = {
  value: 'RENDER_SETTINGS',
  label: 'Render settings'
};

export const ownerSettingsOptionsForUser = (user?: AuthUser) => {
  if (user) {
    return ownerSettingsOptions;
  }
  return [renderSettings];
};

export const ownerSettingsOptions = [
  {
    value: 'SETTLEMENT_SPECIFIC_SETTINGS',
    label: 'Settlement specific settings'
  },
  {
    value: 'ACCOUNT_SETTINGS',
    label: 'Account settings'
  },
  renderSettings
];

interface UiComponentState {
  hidden: boolean,
  hiddenByUser: boolean,
  hiddenByCode: boolean
}

interface OverlayState {
  hiddenComponents: Record<UiComponent, UiComponentState>,
  hideComponent: (a: UiComponent) => void,
  hideComponents: (a: UiComponent[]) => void,
  hideComponentByUser: (a: UiComponent) => void,
  toggleComponentByUser: (a: UiComponent) => void,
  showAllComponents: () => void,
  showAllComponentsByUser: () => void,
  hideAllComponents: () => void,
  hideAllComponentsExcept: (a: UiComponent) => void,
  hideAllComponentsExceptByUser: (a: UiComponent) => void,
  hideAllComponentsExceptMultiple: (a: UiComponent[]) => void
}

const useOverlayStore = create<OverlayState>()((set, get) => ({
  hiddenComponents: {
    ['INITIATE_EDIT_BUTTONS']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['MODAL']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['BUILDING_INFORMATION']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['PERSON_INFORMATION']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['TIME_CONTROLS']: { hidden: isSmallScreenDevice, hiddenByUser: isSmallScreenDevice, hiddenByCode: false },
    ['EDIT_TOWN_LAYOUT']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['SETTLEMENT_ACTIONS']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['SETTLEMENT_FILTERS']: { hidden: isSmallScreenDevice, hiddenByUser: isSmallScreenDevice, hiddenByCode: false },
    ['MAP_LEGEND']: { hidden: isSmallScreenDevice, hiddenByUser: isSmallScreenDevice, hiddenByCode: false },
    ['MAP_SCALE']: { hidden: false, hiddenByUser: false, hiddenByCode: false },
    ['SETTLEMENT_NAME']: { hidden: isSmallScreenDevice, hiddenByUser: isSmallScreenDevice, hiddenByCode: false },
    ['RENDER_SETTINGS']: { hidden: true, hiddenByUser: true, hiddenByCode: false },
    ['ZOOM_CONTROLS']: { hidden: false, hiddenByUser: false, hiddenByCode: false }
  },
  hideComponent: (component) => {
    set(produce<OverlayState>(state => {
      state.hiddenComponents[component].hidden = true;
      state.hiddenComponents[component].hiddenByCode = true;
    }));
  },
  hideComponents: (components) => {
    set(produce<OverlayState>(state => {
      components.forEach(component => {
        state.hiddenComponents[component].hidden = true;
        state.hiddenComponents[component].hiddenByCode = true;
      });
    }));
  },
  hideComponentByUser: (component) => {
    set(produce<OverlayState>(state => {
      state.hiddenComponents[component].hidden = true;
      state.hiddenComponents[component].hiddenByUser = true;
    }));
  },
  toggleComponentByUser: (component) => {
    set(produce<OverlayState>(state => {
      if (get().hiddenComponents[component].hiddenByUser) {
        state.hiddenComponents[component].hidden = get().hiddenComponents[component].hiddenByCode;
        state.hiddenComponents[component].hiddenByUser = false;
      } else {
        state.hiddenComponents[component].hidden = true;
        state.hiddenComponents[component].hiddenByUser = true;
      }
    }));
  },
  showAllComponents: () => {
    set(produce<OverlayState>(state => UI_COMPONENTS.forEach(component => {
      state.hiddenComponents[component].hidden = get().hiddenComponents[component].hiddenByUser;
      state.hiddenComponents[component].hiddenByCode = false;
    })));
  },
  showAllComponentsByUser: () => {
    set(produce<OverlayState>(state => UI_COMPONENTS.forEach(component => {
      state.hiddenComponents[component].hidden = get().hiddenComponents[component].hiddenByCode;
      state.hiddenComponents[component].hiddenByUser = false;
    })));
  },
  hideAllComponents: () => {
    set(produce<OverlayState>(state => UI_COMPONENTS.forEach(component => {
      state.hiddenComponents[component].hidden = true;
      state.hiddenComponents[component].hiddenByCode = true;
    })));
  },
  hideAllComponentsExcept: (exemptComponent) => {
    set(produce<OverlayState>(state => UI_COMPONENTS.filter((component) => component !== exemptComponent).forEach(component => {
      state.hiddenComponents[component].hidden = true;
      state.hiddenComponents[component].hiddenByCode = true;
    })));
  },
  hideAllComponentsExceptByUser: (exemptComponent) => {
    set(produce<OverlayState>(state => UI_COMPONENTS.filter(component => component !== exemptComponent).forEach(component => {
      state.hiddenComponents[component].hidden = true;
      state.hiddenComponents[component].hiddenByUser = true;
    })));
  },
  hideAllComponentsExceptMultiple: (exemptComponents) => {
    set(produce<OverlayState>(state => UI_COMPONENTS.filter((component) => !exemptComponents.includes(component)).forEach(component => {
      state.hiddenComponents[component].hidden = true;
      state.hiddenComponents[component].hiddenByCode = true;
    })));
  }
}));

export const useIsHiddenComponent = (component: UiComponent) => useOverlayStore((state) => state.hiddenComponents[component].hidden);
export const useIsHiddenForEditComponent = () => useIsHiddenComponent('INITIATE_EDIT_BUTTONS');
export function useIsHiddenComponentAndNotFoundry(component: UiComponent): boolean {
  const isHidden = useIsHiddenComponent(component)
  const isFoundry = useContext() === 'FOUNDRY_VTT'
  return isHidden && !isFoundry
}
export function useIsHiddenComponentOrFoundry(component: UiComponent): boolean {
  const isHidden = useIsHiddenComponent(component)
  const isFoundry = useContext() === 'FOUNDRY_VTT'
  return isHidden || isFoundry
}

export function useAllHiddenByUser(): readonly UiComponent[]  {
  return useOverlayStore(state =>
    Object.entries(state.hiddenComponents)
      .filter(([, value]) => value.hiddenByUser)
      .map(([component]) => component as UiComponent)
  )
}

export const hideAllComponentsExceptMultiple = useOverlayStore.getState().hideAllComponentsExceptMultiple;
export const hideAllComponentsExcept = useOverlayStore.getState().hideAllComponentsExcept;
export const hideAllComponents = useOverlayStore.getState().hideAllComponents;
export const hideComponentByUser = useOverlayStore.getState().hideComponentByUser;
export const showAllComponents = useOverlayStore.getState().showAllComponents;
export const hideComponents = useOverlayStore.getState().hideComponents;
export const toggleComponentByUser = useOverlayStore.getState().toggleComponentByUser;
