import {create} from 'zustand';
import {getGPUTier, TierResult} from 'detect-gpu';
import {WEBGL_SUPPORTED} from "./utils/webGlSupportedStore.ts";
import {TextLayer} from "../interface/filters/layer/useLayerStore.ts";


export type AntialiasOption = 'DEFAULT_HARDWARE' | 'MSAA' | 'NONE';

const ANTIALIAS_COOKIE = 'ANTIALIAS_COOKIE';
const SHADOWS_COOKIE = 'SHADOWS_COOKIE';
const BUILDING_RENDER_OPTION_COOKIE = 'BUILDING_SHAPES_COOKIE';
const TREE_PERCENTAGE_COOKIE = 'TREE_PERCENTAGE_COOKIE';
const THREE_DIMENSIONS_COOKIE = 'THREE_DIMENSIONS_COOKIE';
const SVG_RENDERER_COOKIE = 'SVG_RENDERER_COOKIE';
const DISTRICT_TEXT_OPTION_COOKIE = 'DISTRICT_TEXT_OPTION_COOKIE';
const DISTRICT_TEXT_SCALING_COOKIE = 'DISTRICT_TEXT_SCALING_COOKIE';
const CUSTOM_TEXT_SCALE_COOKIE = 'CUSTOM_TEXT_SCALE_COOKIE';
const DEFAULT_TEXT_LAYER_COOKIE = 'DEFAULT_TEXT_LAYER_COOKIE';

export type BuildingRoofRenderOption = 'FLAT' | 'FLAT_TEXTURE' | 'FLAT_3D' | '3D_ROOF'
export type TextRenderOption = 'FLAT' | 'ROTATED' | 'BENDY' | 'FLAT_UNIFORM' | 'ROTATED_UNIFORM'

interface GpuTierState {
  data: TierResult | null,
  isRequesting: boolean,
  setIsRequesting: () => void,
  setData: (data: TierResult) => void
}

const useGpuTierStore = create<GpuTierState>()(set => ({
  data: null,
  isRequesting: false,
  setIsRequesting: () => set({isRequesting: true}),
  setData: data => set({data, isRequesting: false})
}));

interface RenderOptionsState {
  antialiasing: AntialiasOption | null,
  shadows: string | null,
  buildingRoofRenderOption: BuildingRoofRenderOption | null,
  treePercentage: string | null,
  threeDimensions: string | null,
  districtText: TextRenderOption | null,
  districtTextScaling: string | null,
  customTextScale: string | null,
  svgRenderer: string | null,
  defaultTextLayer: string | null,
  forceRefresh: boolean,
  setOptions: (antialiasing: AntialiasOption,
               shadows: boolean,
               buildingRoofRenderOption: BuildingRoofRenderOption,
               treePercentage: number,
               threeDimensions: boolean,
               districtText: TextRenderOption,
               districtTextScaling: boolean,
               customTextScale: number,
               svgRenderer: boolean,
               defaultTextLayer: TextLayer) => void,
  enableSvgRenderer: () => void,
  confirmRefresh: () => void
}

function getAntialiasing(): AntialiasOption | null {
  const value = window.localStorage.getItem(ANTIALIAS_COOKIE);
  if (value === 'SSAO') {
    return 'MSAA'
  }
  return value as AntialiasOption | null
}

export const useRenderOptionsStore = create<RenderOptionsState>()((set) => ({
  antialiasing: getAntialiasing(),
  shadows: window.localStorage.getItem(SHADOWS_COOKIE),
  buildingRoofRenderOption: window.localStorage.getItem(BUILDING_RENDER_OPTION_COOKIE) as BuildingRoofRenderOption | null,
  treePercentage: window.localStorage.getItem(TREE_PERCENTAGE_COOKIE),
  threeDimensions: window.localStorage.getItem(THREE_DIMENSIONS_COOKIE),
  svgRenderer: window.localStorage.getItem(SVG_RENDERER_COOKIE),
  districtText: window.localStorage.getItem(DISTRICT_TEXT_OPTION_COOKIE) as TextRenderOption | null,
  districtTextScaling: window.localStorage.getItem(DISTRICT_TEXT_SCALING_COOKIE),
  customTextScale: window.localStorage.getItem(CUSTOM_TEXT_SCALE_COOKIE),
  defaultTextLayer: window.localStorage.getItem(DEFAULT_TEXT_LAYER_COOKIE),
  forceRefresh: false,
  setOptions: (antialiasing, shadows, buildingRoofRenderOption,
               treePercentage, threeDimensions, districtText,
               districtTextScaling, customTextScale, svgRenderer, defaultTextLayer) => {
    window.localStorage.setItem(ANTIALIAS_COOKIE, antialiasing);
    window.localStorage.setItem(SHADOWS_COOKIE, String(shadows));
    window.localStorage.setItem(DISTRICT_TEXT_SCALING_COOKIE, String(districtTextScaling));
    window.localStorage.setItem(DISTRICT_TEXT_OPTION_COOKIE, districtText);
    window.localStorage.setItem(BUILDING_RENDER_OPTION_COOKIE, buildingRoofRenderOption);
    window.localStorage.setItem(TREE_PERCENTAGE_COOKIE, String(treePercentage));
    window.localStorage.setItem(THREE_DIMENSIONS_COOKIE, String(threeDimensions));
    window.localStorage.setItem(CUSTOM_TEXT_SCALE_COOKIE, String(customTextScale));
    window.localStorage.setItem(SVG_RENDERER_COOKIE, String(svgRenderer));
    window.localStorage.setItem(DEFAULT_TEXT_LAYER_COOKIE, defaultTextLayer);
    set({
      antialiasing,
      shadows: `${shadows}`,
      buildingRoofRenderOption,
      treePercentage: String(treePercentage),
      threeDimensions: String(threeDimensions),
      districtTextScaling: String(districtTextScaling),
      customTextScale: String(customTextScale),
      districtText: districtText,
      forceRefresh: true,
      svgRenderer: String(svgRenderer),
      defaultTextLayer
    });
  },
  enableSvgRenderer: () => set({svgRenderer: "true"}),
  confirmRefresh: () => set({forceRefresh: false})
}));

export interface RenderState {
  antialiasing: AntialiasOption,
  shadows: boolean,
  buildingRoofRenderOption: BuildingRoofRenderOption,
  districtText: TextRenderOption,
  districtTextScaling: boolean,
  customTextScale: number,
  treePercentage: number,
  threeDimensional: boolean,
  defaultTextLayer: TextLayer,
  svgRenderer: boolean,
  ready: boolean
}

export function useRenderOptions(): RenderState;
export function useRenderOptions<T>(selector: (state: RenderState) => T): T;
export function useRenderOptions<T>(selector?: (state: RenderState) => T): T | RenderState {
  const {
    antialiasing,
    shadows,
    districtText,
    districtTextScaling,
    customTextScale,
    buildingRoofRenderOption,
    treePercentage,
    threeDimensions,
    defaultTextLayer,
    svgRenderer
  } = useRenderOptionsStore();
  const {data, isRequesting, setIsRequesting, setData} = useGpuTierStore();

  if ([antialiasing, shadows, buildingRoofRenderOption, treePercentage, svgRenderer].includes(null) && !data && !isRequesting) {
    setIsRequesting();
    getGPUTier().then(result => {
      setData(result);
    });
  }

  const svgRendererVal = !WEBGL_SUPPORTED || (svgRenderer === null ? false : svgRenderer === 'true')

  const state: RenderState = {
    antialiasing: antialiasing || appleOrIntelPerformance(data, 'DEFAULT_HARDWARE', 'MSAA'),
    shadows: shadows === null ? mobileOrLowPerformance(data, false, true) : shadows === 'true',
    districtText: districtText !== null ? districtText : 'BENDY',
    districtTextScaling: districtTextScaling === null ? true : districtTextScaling === 'true',
    customTextScale: customTextScale ? Number(customTextScale) : 0.8,
    buildingRoofRenderOption: buildingRoofRenderOption !== null ? buildingRoofRenderOption : mobileOrLowPerformance<BuildingRoofRenderOption>(data, 'FLAT_TEXTURE', 'FLAT_3D'),
    treePercentage: treePercentage ? Number(treePercentage) : mobileOrLowPerformance(data, 50, 100),
    defaultTextLayer: defaultTextLayer ? defaultTextLayer as TextLayer : 'DISTRICTS',
    threeDimensional: !svgRendererVal && (threeDimensions === null ? false : threeDimensions === 'true'),
    svgRenderer: svgRendererVal,
    ready: ![antialiasing, shadows, buildingRoofRenderOption, treePercentage].includes(null) || !!data
  };
  if (selector) {
    return selector(state);
  }
  return state as T;
}

export function getDefaultTextLayer(): TextLayer {
  const value = window.localStorage.getItem(DEFAULT_TEXT_LAYER_COOKIE)
  if (value === null) return 'DISTRICTS'
  return value as TextLayer
}

function mobileOrLowPerformance<T>(data: TierResult | null, mobile: T, notMobile: T) {
  if (!data || data.isMobile || data.tier === 1) {
    return mobile;
  }
  return notMobile;
}

function appleOrIntelPerformance<T>(data: TierResult | null, low: T, high: T) {
  if (!data || data.gpu?.startsWith('intel') || data.gpu?.startsWith('apple')) {
    return low;
  }
  return high;
}
